<template>
  <list :log="true" :tableDatas="tableDatas" :getTableDatas="getTableDatas"></list>
</template>

<script>
import List from '../components/List.vue'
import {log} from '../api'
export default {
  components: { List },
  name: "Log",
  data() {
    return {
      tableDatas: [],
    };
  },
  created() {
    this.getTableDatas();
  },
  methods: {
    getTableDatas(order) {
      log.get({order}).then(res => {
        if(res){
          // 若返回的是数组，则直接赋值
          if(res instanceof Array){
            this.tableDatas = res;
          }else{// 否则替换数组中的元素
            this.tableDatas.splice(0, 1, res);
          }
        }
      });
    },
  },
}
</script>

<style>

</style>